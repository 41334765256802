import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";

const Products = () => {
  const [selectedApp, setSelectedApp] = useState({
    title: "App Details",
    description:
      "This section shows detailed information about the selected app. Click on an app icon from the left column to view its details here.",
  });

  const appDetails = {
    facebook: {
      title: "Facebook",
      description: "Facebook is a social media platform where you can connect with friends, share photos, and join communities.",
    },
    twitter: {
      title: "Twitter",
      description: "Twitter is a microblogging platform where users share short messages called tweets.",
    },
    instagram: {
      title: "Instagram",
      description: "Instagram is a photo and video sharing app with features like stories and reels.",
    },
    linkedin: {
      title: "LinkedIn",
      description: "LinkedIn is a professional networking platform used to connect with colleagues and find job opportunities.",
    },
  };

  return (
    <div className="container-fluid" style={{ height: "82vh" }}>
      <div className="row h-100">
        {/* First Column */}
        <div className="col-3 d-flex flex-column align-items-center justify-content-center">
          <div
            className="mb-4 p-3 rounded-circle bg-primary text-white"
            style={{ cursor: "pointer" }}
            onClick={() => setSelectedApp(appDetails.facebook)}
          >
            <FaFacebook size={50} />
          </div>
          <div
            className="mb-4 p-3 rounded-circle bg-info text-white"
            style={{ cursor: "pointer" }}
            onClick={() => setSelectedApp(appDetails.twitter)}
          >
            <FaTwitter size={50} />
          </div>
          <div
            className="mb-4 p-3 rounded-circle bg-danger text-white"
            style={{ cursor: "pointer" }}
            onClick={() => setSelectedApp(appDetails.instagram)}
          >
            <FaInstagram size={50} />
          </div>
          <div
            className="mb-4 p-3 rounded-circle bg-secondary text-white"
            style={{ cursor: "pointer" }}
            onClick={() => setSelectedApp(appDetails.linkedin)}
          >
            <FaLinkedin size={50} />
          </div>
        </div>

        {/* Second Column */}
        <div className="col-9 d-flex align-items-center justify-content-center">
          <div
            style={{
              width: "80%",
              height: "50%",
              backgroundColor: "rgba(60, 60, 60, 0.4)",
              color: "white",
              borderRadius: "10px",
              padding: "20px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <h2>{selectedApp.title}</h2>
            <p>{selectedApp.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
