import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import Header from './Pages/Header/Header';
import Home from './Pages/MainPages/Home';
import Footer from './Pages/Footer/Footer';
import Products from './Pages/MainPages/Products';
import Effect from "./Pages/AnimatedBackground/vanta.dots";
import * as THREE from "three";


function App() {
  const [currentTab, setCurrentTab] = useState('home'); // State to track the current tab

  const vantaRef = useRef(null);
  const vantaEffect = useRef(null);

  useEffect(() => {
    if (!vantaEffect.current && vantaRef.current) {
      try {
        vantaEffect.current = new Effect({
          el: vantaRef.current,
          THREE: THREE,
          color: 0xff8820, // Customize colors
          backgroundColor: 0x000000, // Background color
          size: 1.5, // Adjust size
          spacing: 20,
          showLines: false
        });
      } catch (error) {
        console.error("Failed to initialize Vanta effect:", error);
      }
    }

    return () => {
      if (vantaEffect.current) {
        vantaEffect.current.destroy();
        vantaEffect.current = null;
      }
    };
  }, []);
  // Render content based on the current tab
  const renderContent = () => {
    switch (currentTab) {
      case 'home':
        return <Home />;
      case 'products':
        return <Products />;
      case 'aboutus':
        return <div style={{ padding: '20px', textAlign: 'center', color: 'white' }}>Other Placeholder</div>;
      default:
        return <Home />;
    }
  };

  return (
    <div className="App" ref={vantaRef} style={{ minHeight: '100vh' }}>
      <Header setCurrentTab={setCurrentTab} />
      <main>{renderContent()}</main>
      <Footer />
    </div>
  );
}

export default App;
