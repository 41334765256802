import React, { useState } from 'react';
import './Header.css';

function Header({ setCurrentTab }) {
  //const [activeTab, setActiveTab] = useState('home');

  return (
    <header className="custom-header">
      <nav className="custom-nav">
        <div className="nav-container">
        <ul className="nav-links">
          <li
            className={`nav-item`}
            onClick={() => setCurrentTab('home')}
          >
            Home
          </li>
          <li
            className={`nav-item`}
            onClick={() => setCurrentTab('products')}
          >
            Products
          </li>
          <li
            className={`nav-item`}
            onClick={() => setCurrentTab('aboutus')}
          >
            About Us
          </li>
        </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header;
