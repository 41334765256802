import vantaGlobe from '../AnimatedBackground/vanta.globe';
import React, { useEffect, useRef } from 'react';
//import * as THREE from 'three';
import './Home.css';

function Home() {
  /*const vantaRef = useRef(null);

  useEffect(() => {
    let vantaEffect = null;

    if (vantaRef.current) {
      vantaEffect = vantaGlobe({
        el: vantaRef.current, // Attach to the container
        THREE: THREE, // Pass THREE.js instance
        color: 0x442cd3,
        color2: 0xfffff5,
        backgroundColor: 0x000000,
        size: 1,
        showLines: true,
      });
    }

    // Cleanup on unmount
    return () => {
      if (vantaEffect && vantaEffect.destroy) {
        vantaEffect.destroy(); // Ensure proper cleanup
      }
    };
  }, []);*/

  return (
    <div>
      <section id="home" className="container d-flex flex-column align-items-start justify-content-center py-5">
        <div className="text-start">
          <h1 className="display-4 fw-bold">
            BitLitic
          </h1>
          <p className="lead">
            Innovating one bit at a time
          </p>
        </div>
      </section>
    </div>
  );
}

export default Home;
