import * as THREE from 'three';
import VantaBase from './_base.js';
import { rn } from './helpers.js';

class Effect extends VantaBase {
  static initClass() {
    this.prototype.defaultOptions = {
      color: 0xff8820,
      color2: 0xff8820,
      backgroundColor: 0x222222,
      size: 3,
      spacing: 35,
      showLines: true,
    };
  }

  onInit() {
    const camera = (this.camera = new THREE.PerspectiveCamera(
      50,
      this.width / this.height,
      0.1,
      5000
    ));
    camera.position.set(0, 250, 50);
    camera.tx = 0;
    camera.ty = 50;
    camera.tz = 350;
    camera.lookAt(0, 0, 0);
    this.scene.add(camera);

    const starsGeometry = (this.starsGeometry = new THREE.BufferGeometry());
    const points = [];
    const space = this.options.spacing;

    for (let i = -30; i <= 30; i++) {
      for (let j = -30; j <= 30; j++) {
        const star = new THREE.Vector3(
          i * space + space / 2,
          rn(0, 5) - 150,
          j * space + space / 2
        );
        points.push(star);
      }
    }
    starsGeometry.setFromPoints(points);

    const starsMaterial = new THREE.PointsMaterial({
      color: this.options.color,
      size: this.options.size,
    });
    const starField = (this.starField = new THREE.Points(
      starsGeometry,
      starsMaterial
    ));
    this.scene.add(starField);

    if (this.options.showLines) {
      const material = new THREE.LineBasicMaterial({ color: this.options.color2 });
      const linesGeo = new THREE.BufferGeometry();
      const linePoints = [];
      for (let i = 0; i < 200; i++) {
        const f1 = rn(40, 60);
        const f2 = f1 + rn(12, 20);
        const z = rn(-1, 1);
        const r = Math.sqrt(1 - z * z);
        const theta = rn(0, Math.PI * 2);
        const y = Math.sin(theta) * r;
        const x = Math.cos(theta) * r;
        linePoints.push(new THREE.Vector3(x * f1, y * f1, z * f1));
        linePoints.push(new THREE.Vector3(x * f2, y * f2, z * f2));
      }
      linesGeo.setFromPoints(linePoints);
      this.linesMesh = new THREE.LineSegments(linesGeo, material);
      this.scene.add(this.linesMesh);
    }
  }

  onUpdate() {
    const starsGeometry = this.starsGeometry;

    for (let j = 0; j < starsGeometry.attributes.position.array.length; j += 3) {
      const x = starsGeometry.attributes.position.array[j];
      const y = starsGeometry.attributes.position.array[j + 1];
      const z = starsGeometry.attributes.position.array[j + 2];
      const newY = y + 0.1 * Math.sin(z * 0.02 + x * 0.015 + this.t * 0.02);
      starsGeometry.attributes.position.array[j + 1] = newY;
    }

    starsGeometry.attributes.position.setUsage(THREE.DynamicDrawUsage);
    starsGeometry.computeVertexNormals();
    starsGeometry.attributes.position.needsUpdate = true;

    const c = this.camera;
    const rate = 0.003;
    c.position.x += (c.tx - c.position.x) * rate;
    c.position.y += (c.ty - c.position.y) * rate;
    c.position.z += (c.tz - c.position.z) * rate;
    c.lookAt(0, 0, 0);

    if (this.linesMesh) {
      this.linesMesh.rotation.z += 0.002;
      this.linesMesh.rotation.x += 0.0008;
      this.linesMesh.rotation.y += 0.0005;
    }
  }

  onMouseMove(x, y) {
    this.camera.tx = (x - 0.5) * 100;
    this.camera.ty = 50 + y * 50;
  }

  onRestart() {
    this.scene.remove(this.starField);
  }
}

Effect.initClass();

// Export the Effect class as DOTS
export default Effect;
